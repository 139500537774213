import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/OnDemandPrintListItem_elImage_29977.jpg';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class OnDemandPrintListItem extends Component {

  // Properties used by this component:
  // product_image, product_name, product_price, dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elButton = async (ev) => {
    let newVal = this.props.dataSheetRow.product_image;
    this.props.appActions.updateDataSlot('ds_selected_on_demand_print_image', newVal);
  
    newVal = this.props.dataSheetRow.product_name;
    this.props.appActions.updateDataSlot('ds_selected_on_demand_print_name', newVal);
  
    newVal = this.props.dataSheetRow.product_price;
    this.props.appActions.updateDataSlot('ds_selected_on_demand_print_price', newVal);
  
    newVal = this.props.dataSheetRow.product_id;
    this.props.appActions.updateDataSlot('ds_selected_on_demand_print_id', newVal);
  
    newVal = this.props.dataSheetRow.product_buy_link;
    this.props.appActions.updateDataSlot('ds_selected_on_demand_print_buy_link', newVal);
  
    window.location = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_on_demand_print_buy_link'] : '');
  
  }
  
  
  render() {
    const style_elImage = {
      backgroundImage: 'url('+this.props.appActions.createImageUrlFromProp(this.props.product_image)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const value_text = this.props.product_name;
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_text2 = this.props.product_price;
    
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elButton = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#28c928',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="OnDemandPrintListItem appBg">
        <div className="layoutFlow">
          <div className="elImage">
            <div style={style_elImage} />
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{value_text}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{value_text2}</div>
            </div>
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton} onClick={this.onClick_elButton} >
              {this.props.locStrings.ondemandprintlistitem_button_96695}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
