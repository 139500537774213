// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "home_button_208014";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_text_1004334";
    item['en'] = "Have a big project? ";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_text2_91394";
    item['en'] = "We offer discounted pricing for large ";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_textcopy_130244";
    item['en'] = "prints Please send us the details and";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_textcopy2_160811";
    item['en'] = "your budget so we can begin";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_textcopy3_894489";
    item['en'] = "conversations.";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_button_985468";
    item['en'] = "Custom Quote";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_353334";
    item['en'] = "DFW 3D Printing - All Rights Reserved - 2021";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_text_390526";
    item['en'] = "DFW 3D Printing - All Rights Reserved - 2021";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_buttoncopy_713863";
    item['en'] = "On-Demand Prints";
    
    item = {};
    this.items.push(item);
    item['key'] = "ondemandprintlistitem_text_182208";
    item['en'] = "Name";
    
    item = {};
    this.items.push(item);
    item['key'] = "ondemandprintlistitem_text2_671773";
    item['en'] = "Price";
    
    item = {};
    this.items.push(item);
    item['key'] = "ondemandprintlistitem_button_96695";
    item['en'] = "Order Print";
    
    item = {};
    this.items.push(item);
    item['key'] = "submitondemandprint_text_233430";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "submitondemandprint_text2_486514";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "submitondemandprint_buttoncopy_163820";
    item['en'] = "GO BACK";
    
    item = {};
    this.items.push(item);
    item['key'] = "submitondemandprint_field_551200";
    item['en'] = "Please Enter Your Name";
    
    item = {};
    this.items.push(item);
    item['key'] = "submitondemandprint_fieldcopy_372721";
    item['en'] = "Please Enter Your Email";
    
    item = {};
    this.items.push(item);
    item['key'] = "submitondemandprint_fieldcopy2_658758";
    item['en'] = "Please Enter Your Address";
    
    item = {};
    this.items.push(item);
    item['key'] = "submitondemandprint_textcopy_747851";
    item['en'] = "After you hit “send request”, we will get notified and will follow-up by email with further instructions.";
    
    item = {};
    this.items.push(item);
    item['key'] = "ondemandprintconfirmationscreen_text_80051";
    item['en'] = "Thank you for your submission!\nWe will follow up by email.";
    
    item = {};
    this.items.push(item);
    item['key'] = "submitondemandprint_validator_147272";
    item['en'] = "Please fill in all the fields!";
    
    let storedItems = localStorage.getItem(this.id);
    if (storedItems != null) {
      this.items = JSON.parse(storedItems);
    }
  }

  addItem(item, options) {
    super.addItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  removeItem(item, options) {
    super.removeItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  replaceItemByRowIndex(idx, newItem, options) {
    super.replaceItemByRowIndex(idx, newItem, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
