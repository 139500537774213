import React, { Component } from 'react';
import './App.css';
import Footer from './Footer';
import img_elImage from './images/CustomQuoteScreen_elImage_0.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class HomeScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elButton = async (ev) => {
    // Go to screen 'Custom Quote'
    this.props.appActions.goToScreen('customQuote', { transitionId: 'slideIn_right' });
  
  }
  
  
  onClick_elButtonCopy = async (ev) => {
    // Go to screen 'On-Demand Prints'
    this.props.appActions.goToScreen('onDemandPrints', { transitionId: 'slideIn_right' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f7f7f7',
     };
    
    // Embedded HTML content for element 'embed 2'
    const htmlContent_embed2 = "<!-- Printer: 9047-Creality Ender 3 --><link href=\"https://www.treatstock.com/css/embed-remote.css\" rel=\"stylesheet\" /><iframe class=\"ts-embed-printservice\" width=\"100%\" height=\"650px\" src=\"https://www.treatstock.com/my/print-model3d/widget?posUid=fixedPsPrinter&psPrinterId=9047\" frameborder=\"0\"></iframe>";
    
    const style_elEmbed2 = {
      pointerEvents: 'auto',
     };
    
    const style_elButton = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ed5b15',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonCopy = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ee5b16',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elCard = {
      width: '100%',
      height: '100%',
     };
    const style_elCard_outer = {
      backgroundColor: '#1c15ff',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    return (
      <div className="AppScreen HomeScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="embeddedContent elEmbed2">
            <div style={style_elEmbed2}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_embed2}}></div>
            </div>
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton} onClick={this.onClick_elButton} >
              {this.props.locStrings.home_button_985468}
            </Button>
          </div>
          
          <div className="elButtonCopy">
            <Button className="actionFont" style={style_elButtonCopy} onClick={this.onClick_elButtonCopy} >
              {this.props.locStrings.home_buttoncopy_713863}
            </Button>
          </div>
          
          <div className="hasNestedComps elFooter">
            <div>
              <Footer ref={(el)=> this._elFooter = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elCard" style={style_elCard_outer}>
              <div style={style_elCard} />
            </div>
            
            <div className="elImage" style={style_elImage} />
          </div>
        </div>
      </div>
    )
  }
  
}
