import React, { Component } from 'react';
import './App.css';
import OnDemandPrintListItem from './OnDemandPrintListItem';
import Footer from './Footer';
import img_elImage from './images/CustomQuoteScreen_elImage_0.png';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Button from 'muicss/lib/react/button';

export default class OnDemandPrintsScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      picker: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_category'] : ''),
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  pickerValueChanged_elPicker = (event) => {
    this.setState({picker: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_selected_category", event.target.value);
  }
  
  onClick_elButton = async (ev) => {
    // Go to screen 'Home'
    this.props.appActions.goToScreen('home', { transitionId: 'slideIn_right' });
  
  }
  
  
  onClick_elImage = async (ev) => {
    // Go to screen 'Home'
    this.props.appActions.goToScreen('home', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_onDemandPrinting = this.props.dataSheets['onDemandPrinting'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f7f7f7',
     };
    
    let selection_picker = this.state.picker;
    // Source datasheet and selected data column for picker element 'picker'
    const dataSource_picker = this.props.appActions.getDataSheet('onDemandPrinting');
    const valueColumnName_picker = 'category';
    const labelColumnName_picker = 'category';
    
    const style_elPicker = {
      pointerEvents: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    let filterItems_list = items => {
      // This function filters items for the List / Grid element.
      // There is a variable named 'items' that provides item values.
      let filteredItems = [];
      items.forEach(item => {;
      	if (item['category'].includes(this.props.appActions.dataSlots['ds_selected_category'])) {
      		filteredItems.push(item);
      	}
      });
      items=filteredItems;
      return items;
    }
    items_list = items_list.concat(filterItems_list(this.props.appActions.getDataSheet('onDemandPrinting').items));
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_elButton = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ee5b16',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elCard = {
      width: '100%',
      height: '100%',
     };
    const style_elCard_outer = {
      backgroundColor: '#1c15ff',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen OnDemandPrintsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elPicker">
            <Select className="baseFont" style={style_elPicker}  onChange={this.pickerValueChanged_elPicker} value={selection_picker} >
              {dataSource_picker.items.every(item => {
                return item[valueColumnName_picker] !== selection_picker;
              }) ? <Option value=''/> : null}
              {dataSource_picker.items.map(item => {
                const colValue = item[valueColumnName_picker];
                const labelColValue = item[labelColumnName_picker];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="hasNestedComps elList">
            <ul style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <OnDemandPrintListItem appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'onDemandPrinting'} dataSheetRow={row} {...{ 'product_image': row['product_image'], 'product_name': row['product_name'], 'product_price': row['product_price'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton} onClick={this.onClick_elButton} >
              GO BACK
            </Button>
          </div>
          
          <div className="hasNestedComps elFooter">
            <div>
              <Footer ref={(el)=> this._elFooter = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elCard" style={style_elCard_outer}>
              <div style={style_elCard} />
            </div>
            
            <div className="elImage" style={style_elImage} onClick={this.onClick_elImage}  />
          </div>
        </div>
      </div>
    )
  }
  
}
