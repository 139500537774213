// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';
import datasheet_onDemandPrinting_row0_product_image from './images/datasheet_onDemandPrinting_row0_product_image.png';
import datasheet_onDemandPrinting_row1_product_image from './images/datasheet_onDemandPrinting_row1_product_image.png';
import datasheet_onDemandPrinting_row2_product_image from './images/datasheet_onDemandPrinting_row2_product_image.png';

export default class DataSheet_onDemandPrinting extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['product_buy_link'] = "https://buy.stripe.com/aEU4gXgPJ9RYgKI288";
    item['product_id'] = "1";
    item['product_price'] = "$20 USD";
    item['product_name'] = "Drink Dispenser";
    item['category'] = "Home";
    item['product_image'] = datasheet_onDemandPrinting_row0_product_image;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['product_id'] = "2";
    item['product_buy_link'] = "https://buy.stripe.com/8wMfZFarl8NUeCAeUV";
    item['product_price'] = "$30";
    item['product_name'] = "Torch Stand";
    item['category'] = "Head Shop";
    item['product_image'] = datasheet_onDemandPrinting_row1_product_image;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['product_id'] = "3";
    item['product_buy_link'] = "https://buy.stripe.com/aEUaFlbvp2pwcusfZ0";
    item['product_price'] = "$30";
    item['product_name'] = "Roman Candle Candelabra";
    item['category'] = "Fireworks";
    item['product_image'] = datasheet_onDemandPrinting_row2_product_image;
    item.key = key++;
  }

}
